<template>
  <div
    class="input-wrapper enext-input"
    :class="[
      (rules && rules.length) ? 'enext-input--validable': '',
      errorMessage? 'invalid': '',
      customClasses
    ]"
  >
    <!--needs to be added again-->
    <!--:class="{'enext-input--validable': this.rules && this.rules.length, 'invalid': this.errorMessage}"-->
    <div
      class="text__input"
      v-if="!multiline"
    >
      <span
        class="apply-changes-button"
        @click="triggerEvent"
        v-show="disableLiveUpdate && valChanged"
      >
        <NavigationCheck />
      </span>
      <k-input
        class="input"
        input-class="data-hj-allow"
        v-bind="{ name: uuid+'_inputtext', id: uuid+'_inputtext', ref: uuid + '_inputtext', ...customAttrs }"
        v-on="{ input: inputEvent }"
        autocomplete="off"
        @keydown.enter="triggerEvent"
        @keydown.tab="triggerEvent"
      />
    </div>
    <textarea
      v-else
      v-on="{ input: inputEvent }"
      v-bind="{ name: uuid+'_inputtext', id: uuid+'_inputtext', ref: uuid + '_inputtext', ...customAttrs }"
      class="k-textbox input k-textarea data-hj-allow"
    />
    <ErrorMessage
      :error-message="errorMessage"
      class="enext-input__errormessage enext-input--checkbox__errormessage"
    />
  </div>
</template>

<script>
import {Input} from '@progress/kendo-vue-inputs'
import inputValidationMixin from '~/mixins/input_validation'
import ErrorMessage from '~/components/input-components/ErrorMessage'
import NavigationCheck from '~/assets/icons/navigation_check.svg?component'

export default {
  components: {
    ErrorMessage,
    'k-input': Input,
    NavigationCheck
  },
  mixins: [inputValidationMixin],
  data() {
    return {
      valChanged: false
    }
  },
  inheritAttrs: false,
  props: {
    multiline: {
      type: Boolean,
      default: false,
      required: false
    },
    maxTextLength: {
      type: Number,
      default: -1,
      required: false
    },
    disableLiveUpdate: {
      type: Boolean,
      default: false,
      required: false
    },
    validable: {
      type: Boolean,
      default() {
        return false
      },
      required: false
    }
  },
  methods: {
    inputEvent(event) {
      let value = event.target.value
      if (this.maxTextLength > -1 && value?.length > this.maxTextLength) {
        value = value.substring(0, this.maxTextLength)
      }
      this.$emit('input-value', value)
      this.valChanged = true
    },
    triggerEvent() {
      this.$emit('focusOut')
      this.valChanged = false
    },
    async validateInput() {
      const inputValue = this.$refs[this.uuid + '_inputtext'].computedValue
      const isValid = await this.validate(inputValue)
      return isValid
    }
  },
  computed: {
    customClasses() {
      return this.$attrs?.class ?? ''
    },
    customAttrs() {
      let attrs = {...this.$attrs, class: ''}
      return attrs
    }
  }
}
</script>

<style lang="scss">
.input-wrapper {
  width: 100%;

  .input {
    width: 100%;
  }

  textarea.k-textbox.k-textarea {
    height: auto;
  }
}
</style>
